import SubscriptionController from '../../../controllers/subscriptions.controller'
import PlanController from '@/controllers/plan.controller'

const subscriptionController = new SubscriptionController()
const planController = new PlanController()


export default {
  namespaced: true,
  state: {
    plans: [],
    plans_lensxpert:[],
    loadingPlans: false,
  },
  mutations: {
    /**
     * Sets the `plans` property.
     *
     * @param {Object} state the plan state
     * @param {Object[]} data an array of plans.
     */
    SET_PLANS(state, data) {
      state.plans = data
    },

     /**
     * Sets the `plans_lensxpert` property.
     *
     * @param {Object} state the plan state
     * @param {Object[]} data an array of plans.
     */
      SET_PLANS_LENSXPERT(state, data) {
        state.plans_lensxpert = data
      },

    /**
     * Sets the `loadingPlans` property.
     *
     * @param {Object} state the plan state.
     * @param {boolean} data the loading status.
     */
    SET_LOADING_PLANS(state, data) {
      state.loadingPlans = data
    },
  },
  actions: {
    /**
     * Loads plans according to the given filters.
     *
     * @param {Store} store the vuex store.
     * @param {QueryConstraint[]} payload the fetch filters.
     */
    async fetchPlans({ commit }, payload) {
      commit('SET_LOADING_PLANS', true)

      try {
        const plans = await subscriptionController.getAll(payload)
        commit('SET_PLANS', plans)
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING_PLANS', false)
      }
    },

    /**
     * Loads a plan according to the given id.
     *
     * @param {string} payload the plan id.
     */
    async fetchOnePlan(_, payload) {
      try {
        return subscriptionController.getOne(payload)
      } catch (e) {
        throw e
      }
    },

     /**
     * Creates a lensxpert plan
     *
     * @param {string} payload the plan data
     */
    async createPlan({ commit }, payload) {
      let plan = null
      try {
        plan = await planController.create(payload)
        commit('SET_PLANS_LENSXPERT', plan)
      } catch (e) {
        console.log(e)
      }
      return plan
    },

    /**
     * Loads lensxpert plans according to the given filters.
     *
     * @param {Store} store the vuex store.
     * @param {QueryConstraint[]} payload the fetch filters.
     */
     async fetchPlansLensxpert({ commit }, payload) {
      commit('SET_LOADING_PLANS', true)
      try {
        const plans = await planController.getAll(payload)
        commit('SET_PLANS_LENSXPERT', plans)
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING_PLANS', false)
      }
    },



  },
}
