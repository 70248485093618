import { callHttpsCallableFunction } from '@/api/firebase'

import PlanEntity from '../entities/plan.entity'

import Controller from './base.controller'

import { cF } from '@/utils/cloud-functions'

const collection = 'plans-lensxpert'

/**
 * Class responsible for keeping all the Lensxpert plans logic.
 */
export default class PlanController extends Controller {
  /**
   * Creates a plan into the database.
   *
   * @param {PlanEntity} data the plan data.
   * @param {string} parent the parent path.
   * @returns a plan with its id.
   */
  async create(data, parent) {
    const path = parent ? parent.split('/') : []

    if (!path.includes(collection)) {
      path.push(collection)
    }

    const result = await super.create(path, data)

    return {
      data: new PlanEntity({ ...data, id: result.id }),
      relationPath: result.path,
    }
  }

  /**
   * Gets all plans Lensxpert from the database.
   *
   * @param {QueryConstraint[]} constraints the constraints to be applied to the request.
   * @returns an array of plans.
   */
  async getAll(constraints = []) {
    const snapshot = await super.getAll([collection], constraints)

    return snapshot.docs.map((doc) =>
      PlanEntity.fromFirestore({ ...doc.data(), id: doc.id }),
    )
  }

  /**
   * Subscribes to a given external plan.
   *
   * @param {{userId: string, planId: string, entityPath: string}} data the external plan.
   */
  async subscribeToExternalPlan(data) {
    return callHttpsCallableFunction(cF.subscribeToExternalPlan, data)
  }
}
