import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'

import store from '@/store'
import router from '@/router'

import SoftwarePartnerEntity from '@/entities/software-partner.entity'
import AuthLinkEntity from '@/entities/auth-link.entity'
import AddressModel from '@/models/address.model'
import RoleEnum from '@/utils/roles'
import { readUrl } from '@/utils/images'
import AuthController from '@/controllers/auth.controller'
import PlanController from '@/controllers/plan.controller'
import UserController from '@/controllers/user.controller'

export default function useSoftwarePartnersForm() {
  const toast = useToast()

  const blankData = new SoftwarePartnerEntity({
    nameFantasy: '',
    nameRegistered: '',
    logo: '',
    adminId: '',
    userEmail: '',
    laboratories: [],
    address: new AddressModel({
      line1: '',
      line2: '',
      country: '',
      number: null,
      zipCode: '',
    }),
    status: 'pending',
  })

  const spData = ref(new SoftwarePartnerEntity(blankData))

  const subscription = ref(null)
  const isTrialPlan = ref(false)
  const trialDays = ref(null)
  const laboratories = ref([])

  const refLogoInput = ref(null)
  const logoUrl = ref('')
  const planType = ref(null)

  /**
   * @type {import('@vue/composition-api').Ref<File>}
   */
  const logo = ref(null)

  /**
   * Loads a software partner based on the given id.
   *
   * @param {string} id the software partner id.
   */
  const loadData = async (id) => {
    try {
      spData.value = await store.dispatch(
        'software-partner/fetchOneSoftwarePartner',
        id,
      )

      if (spData.value.adminId.length > 0) {
        let user = await store.dispatch(
          'user/fetchOneUser',
          spData.value.adminId,
        )

        if (user.plan && user.plan.type === 'external') {
          subscription.value = user.plan.id
          planType.value = user.plan.type
        }
      }
    } catch (e) {
      console.log(e)
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('toast.softwarePartnerForm.failure.fetching'),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  /**
   * Resets the software partner data.
   */
  const resetData = () => {
    spData.value = new SoftwarePartnerEntity(blankData)
  }

  /**
   * Sets the given data into the database.
   *
   * @param {SoftwarePartnerEntity} data the data to be set.
   * @param {boolean} profile whether the software partner is being edited from the profile page.
   */
  const onSubmit = async (data, profile) => {
    const id = profile ? data.id : router.currentRoute.params.id

    let alId

    if (id === 'new') {
      // User validations
      const userExists = await new AuthController().checkIfUserExists(
        data.userEmail,
      )
      const userWasInvited = await new AuthController().checkIfEmailWasInvited(
        data.userEmail,
      )
      if (userExists || userWasInvited) {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('firebase.error.emailInUse'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return
      }
    }

    try {
      // Logic for use on profile update only
      if (profile) {
        await store.dispatch('software-partner/setSoftwarePartner', {
          data,
          id: data.id,
        })

        if (logo.value) {
          await store.dispatch('software-partner/uploadLogo', {
            path: data.logo,
            file: logo.value,
          })
        } else if (spData.value.logo && !logoUrl.value) {
          await store.dispatch('software-partner/removeLogo', spData.value.logo)
        }

        spData.value = new SoftwarePartnerEntity(data)

        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toast.softwarePartnerForm.save'),
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        return
      }

      // Save entity
      const result = await store.dispatch(
        'software-partner/setSoftwarePartner',
        {
          data,
          id: id !== 'new' ? id : null,
        },
      )

      // Return if edit
      if (id !== 'new') {
        // Update entity external plan
        if (planType.value === 'external') {
          // Send email granting access
          const emailData = {
            company: data.nameFantasy,
            toEmail: data.userEmail,
            subject: 'Plano Atualizado!',
            name: 'Lensxpert',
            fromEmail: '',
          }
          await new UserController().sendUpdateExternalPlanEmail(emailData)

          // Update entity
          await new PlanController().subscribeToExternalPlan({
            userId: data.adminId,
            planId: subscription.value.id,
            entityPath: `software-partners/${id}`,
          })
        }

        router.push({ name: 'software-partners' })

        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toast.softwarePartnerForm.save'),
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        return
      }

      // Create auth link to invite user to enter the platform
      const authLink = new AuthLinkEntity({
        email: data.userEmail,
        relationPath: result.relationPath,
        role: RoleEnum.SOFTWARE_ADMIN,
        createdAt: data.createdAt,
        updatedAt: new Date().toISOString(),
      })

      // In case user that created is super admin, assign selected plan
      if (planType.value === 'stripe') {
        if (store.state.auth.user.role == RoleEnum.SUPER_ADMIN) {
          authLink.plan = subscription.value.id
          authLink.isTrialPlan = isTrialPlan.value
          authLink.trialDays = trialDays.value
          authLink.planType = 'stripe'
        }
      } else if (planType.value === 'external') {
        if (store.state.auth.user.role == RoleEnum.SUPER_ADMIN) {
          authLink.plan = subscription.value.id
          authLink.isTrialPlan = false
          authLink.trialDays = null
          authLink.planType = 'external'
        }
      } else {
        authLink.plan = null
        authLink.isTrialPlan = false
        authLink.trialDays = null
        authLink.planType = null
      }

      const res = await store.dispatch('auth/createAuthLink', authLink)
      alId = res.id

      await store.dispatch('auth/sendSignInLink', {
        email: data.userEmail,
        authLinkId: alId,
      })

      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('toast.softwarePartnerForm.success'),
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

      router.push({ name: 'software-partners' })
    } catch (e) {
      console.log(e)
      store.dispatch('auth/deleteAuthLink', alId ?? '')

      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('toast.softwarePartnerForm.failure.creating'),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  /**
   * Uploads the logo as an URL.
   */
  const uploadLogo = async () => {
    const files = refLogoInput.value.files

    if (!files || !files.length) {
      return
    }

    if (!files[0].type.match('image/*')) {
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t(
            'toast.software_partner_form.failure.type-not-supported',
          ),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return
    }

    if (files[0].size > 5000000) {
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('toast.softwarePartnerForm.failure.sizeNotAllowed'),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return
    }

    logo.value = files[0]
    logoUrl.value = await readUrl(files[0])
  }

  /**
   * Removes the logo.
   */
  const removeLogo = () => {
    refLogoInput.value.value = ''
    logo.value = null
    logoUrl.value = ''
  }

  return {
    spData,
    resetData,
    loadData,
    onSubmit,
    subscription,
    laboratories,
    isTrialPlan,
    trialDays,
    uploadLogo,
    removeLogo,
    logo,
    logoUrl,
    refLogoInput,
    planType,
  }
}
