<template>
  <b-card v-if="spData">
    <b-row align-h="between" class="mx-0">
      <div class="d-flex">
        <feather-icon icon="FileTextIcon" size="19" />
        <h4 class="mb-0 ml-50">
          {{ $t('admin.labs.softwarePartnerForm.generalInfo') }}
        </h4>
      </div>
    </b-row>

    <validation-observer
      ref="refFormObserver"
      #default="{ handleSubmit, invalid }"
    >
      <b-form class="mt-1" @submit.prevent="handleSubmit(submit(spData))">
        <b-row>
          <b-col v-if="profile" cols="12" md="2" lg="2">
            <input
              title="logo"
              type="file"
              accept="image/jpeg, image/png"
              ref="refLogoInput"
              style="display: none"
              @change="uploadLogo($event)"
            />

            <b-row align-h="center" class="m-0 p-0">
              <b-avatar
                rounded
                size="120px"
                variant="light-primary"
                :src="logoUrl"
                :text="spData.nameFantasy[0] || spData.nameRegistered[0]"
              />
            </b-row>

            <b-row align-h="center" class="m-0 p-0 mt-1">
              <b-button
                id="upload"
                size="sm"
                variant="outline-primary"
                @click="refLogoInput.click()"
              >
                <feather-icon icon="UploadIcon" />
              </b-button>
              <b-tooltip triggers="hover" placement="bottom" target="upload">
                <b>{{ $t('admin.labs.softwarePartnerForm.upload') }}</b>
              </b-tooltip>

              <b-button
                v-if="logoUrl"
                id="remove"
                class="ml-1"
                size="sm"
                variant="outline-danger"
                @click="removeLogo()"
              >
                <feather-icon icon="XIcon" />
              </b-button>
              <b-tooltip triggers="hover" placement="bottom" target="remove">
                <b>{{ $t('admin.labs.softwarePartnerForm.delete') }}</b>
              </b-tooltip>
            </b-row>
          </b-col>

          <b-col
            cols="12"
            :md="profile ? '10' : '12'"
            :lg="profile ? '10' : '12'"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('admin.labs.form.fantasyName')"
              rules="required"
            >
              <b-form-group
                :label="$t('admin.labs.form.fantasyName')"
                label-for="nameFantasy"
              >
                <b-form-input
                  v-model="spData.nameFantasy"
                  class="form-control"
                  :placeholder="$t('admin.labs.form.fantasyName')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              :name="$t('admin.labs.form.registeredName')"
              rules="required"
            >
              <b-form-group
                :label="$t('admin.labs.form.registeredName')"
                label-for="nameRegistered"
              >
                <b-form-input
                  v-model="spData.nameRegistered"
                  class="form-control"
                  :placeholder="$t('admin.labs.form.registeredName')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              :name="$t('opticForm.sectionField8')"
              rules="required"
            >
              <b-form-group
                :label="$t('opticForm.sectionField8')"
                label-for="CNPJ"
              >
                <cleave
                  v-model="spData.cnpj"
                  class="form-control"
                  :options="cnpjDelimiter"
                  :placeholder="$t('opticForm.sectionField8')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Subscription Plan -->
            <div v-if="isSuperAdmin">
              <!-- Choose between Stripe and Lensxpert plans -->
              <b-form-group
                v-slot="{ ariaDescribedby }"
                :label="$t('opticForm.sectionField9')"
              >
                <b-row class="mx-0">
                  <b-form-radio
                    v-model="planType"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="external"
                  >
                    External
                  </b-form-radio>
                  <b-form-radio
                    v-if="$route.params.id == 'new'"
                    class="ml-1"
                    v-model="planType"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="stripe"
                  >
                    Stripe
                  </b-form-radio>
                </b-row>
              </b-form-group>
              <div v-if="planType === 'stripe'">
                <b-row align-v="center" align-h="between">
                  <b-col cols="10">
                    <validation-provider
                      #default="validationContext"
                      :name="$t('admin.labs.form.subscriptionPlan')"
                    >
                      <b-form-group
                        :label="$t('admin.labs.form.subscriptionPlan')"
                        label-for="subscription"
                      >
                        <v-select
                          v-model="subscription"
                          label="description"
                          :placeholder="$t('admin.labs.form.subscriptionPlan')"
                          :options="plans"
                          :clearable="false"
                          :disabled="
                            [
                              'pending-authorization',
                              'pending-payment',
                            ].includes(spData.status)
                          "
                        />
                      </b-form-group>

                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>

                  <!-- Is trial Plan -->
                  <b-col v-if="subscription">
                    <b-row align-h="end" class="mr-1">
                      <span class="mr-1">{{
                        $t('admin.labs.softwarePartnerForm.trialPlan')
                      }}</span>
                      <b-form-checkbox v-model="isTrialPlan" />
                    </b-row>
                  </b-col>
                </b-row>
                <validation-provider
                  v-if="isTrialPlan"
                  #default="validationContext"
                  rules="required|integer|positive"
                  :name="$t('admin.labs.form.trialDays')"
                >
                  <b-form-group
                    :label="$t('admin.labs.form.trialDays')"
                    label-for="trial-days"
                  >
                    <b-form-input
                      v-model="trialDays"
                      id="trial-days"
                      :placeholder="$t('admin.labs.form.trialDays')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>

              <div v-if="planType === 'external'">
                <validation-provider
                  #default="validationContext"
                  :name="$t('admin.labs.form.subscriptionPlan')"
                >
                  <b-form-group
                    :label="$t('admin.labs.form.subscriptionPlan')"
                    label-for="subscription"
                  >
                    <v-select
                      v-model="subscription"
                      label="name"
                      :placeholder="$t('admin.labs.form.subscriptionPlan')"
                      :options="externalPlans"
                      :clearable="false"
                    />
                  </b-form-group>

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </div>

            <validation-provider
              v-if="!profile && !isFromRequestAccessForm"
              #default="validationContext"
              :name="$t('admin.labs.form.spAdmin')"
              rules="required|email"
            >
              <b-form-group
                :label="$t('admin.labs.form.spAdmin')"
                label-for="adminEmail"
              >
                <b-form-input
                  v-model="spData.userEmail"
                  id="adminEmail"
                  :placeholder="$t('admin.labs.form.adminEmail')"
                  :disabled="$route.params.id !== 'new'"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Header: Address -->
        <AddressForm :address="spData.address" />
        <!-- Header: Address -->

        <b-row class="mt-2 pr-1" align-h="end" v-if="!isFromRequestAccessForm">
          <b-button
            v-if="!profile"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :to="{ name: 'software-partners' }"
          >
            {{ $t('admin.labs.softwarePartnerForm.cancel') }}
          </b-button>
          <b-button
            type="submit"
            :variant="invalid ? 'secondary' : 'primary'"
            :disabled="loading || invalid"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            <b-spinner v-if="loading" small></b-spinner>

            <span v-else>{{ $t('admin.labs.softwarePartnerForm.save') }}</span>
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTooltip,
  BFormInvalidFeedback,
  BSpinner,
  BFormRadio,
} from 'bootstrap-vue'
import { onUnmounted } from 'vue-demi'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

import { computed } from '@vue/composition-api'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, integer, email, positive } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

import store from '@/store'
import router from '@/router'

import labStoreModule from '@/views/sections/labs/labStoreModule'
import planStoreModule from '@/views/admin/plans/planStoreModule'
import softwarePartnerStoreModule from './softwarePartnerStoreModule'
import useSoftwarePartnerForm from './useSoftwarePartnerForm'

import LabsList from './components/LabsList.vue'
import SoftwarePartnerEntity from '@/entities/software-partner.entity'

import { cnpjDelimiter } from '@/utils/formatters'
import RoleEnum from '@/utils/roles'
import { createStorageUrl, getExtension } from '@/utils/images'

import AddressForm from '@/views/sections/components/AddressForm.vue'
import userStoreModule from '@/views/admin/users/userStoreModule'

export default {
  name: 'SoftwarePartnerForm',

  props: {
    profile: Boolean,
    data: {
      type: Object,
      required: false,
    },
    isFromRequestAccessForm: Boolean,
  },

  components: {
    BAvatar,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTooltip,
    BFormInvalidFeedback,
    BSpinner,
    BFormRadio,
    Cleave,

    vSelect,
    AddressForm,

    ValidationProvider,
    ValidationObserver,

    LabsList,
  },

  data() {
    return {
      required,
      integer,
      email,
      positive,
      cnpjDelimiter,
      isNew: false,
      viewLabs: false,
    }
  },

  methods: {
    async getLaboratories() {
      let path = `software-partners/${this.$route.params.id}`
      if (
        store.state.auth.user.adminDocRef &&
        store.state.auth.user.adminDocRef.length > 0
      ) {
        path = store.state.auth.user.adminDocRef
      }
      await this.$store.dispatch('laboratory/fetchLabs', path)
      this.viewLabs = true
    },
  },
  watch: {
    subscription(val) {
      if (val && val.id === undefined) {
        if (this.planType === 'external') {
          this.subscription = this.externalPlans.find(
            (el) => el.id === this.subscription,
          )
        }
      }
    },
    spData: {
      deep: true,
      handler(val) {
        if (this.isFromRequestAccessForm) {
          this.$emit('update-data', val)

          if (!this.refFormObserver) {
            return
          }

          setTimeout(() => {
            this.$emit('update-valid', this.refFormObserver._data.flags.valid)
          }, 100)
        }
      },
    },
  },
  computed: {
    labs() {
      return this.$store.state.laboratory.labs
    },
    isSuperAdmin() {
      return this.$store.state.auth.user.role === RoleEnum.SUPER_ADMIN
        ? true
        : false
    },
    plans() {
      const softPlan = this.$store.state.plan.plans.find(
        (el) => el.metadata && el.metadata.type === 'software-partner',
      )
      return (
        softPlan?.prices
          .filter((el) => el.active == true)
          .sort((a, b) => a.description.localeCompare(b.description)) ?? []
      )
    },
    externalPlans() {
      return this.$store.state.plan.plans_lensxpert || []
    },
  },

  created() {
    this.isNew = this.$route.params.id === 'new' ? true : false
  },

  setup(props) {
    const SP_APP_STORE_MODULE_NAME = 'software-partner'
    const LAB_APP_STORE_MODULE_NAME = 'laboratory'
    const PLAN_APP_STORE_MODULE_NAME = 'plan'
    const USER_APP_STORE_MODULE_NAME = 'user'

    if (!store.hasModule(PLAN_APP_STORE_MODULE_NAME)) {
      store.registerModule(PLAN_APP_STORE_MODULE_NAME, planStoreModule)
    }
    if (!store.hasModule(LAB_APP_STORE_MODULE_NAME)) {
      store.registerModule(LAB_APP_STORE_MODULE_NAME, labStoreModule)
    }
    if (!store.hasModule(SP_APP_STORE_MODULE_NAME)) {
      store.registerModule(SP_APP_STORE_MODULE_NAME, softwarePartnerStoreModule)
    }
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }

    onUnmounted(() => {
      store.hasModule(SP_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(SP_APP_STORE_MODULE_NAME) &&
        store.hasModule(PLAN_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(PLAN_APP_STORE_MODULE_NAME) &&
        store.hasModule(LAB_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(LAB_APP_STORE_MODULE_NAME) &&
        store.hasModule(USER_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    if (!props.isFromRequestAccessForm) {
      store.dispatch('plan/fetchPlans')
      store.dispatch('plan/fetchPlansLensxpert')
    }

    const {
      spData,
      resetData,
      loadData,
      onSubmit,
      subscription,
      isTrialPlan,
      trialDays,
      uploadLogo,
      removeLogo,
      logo,
      logoUrl,
      refLogoInput,
      planType,
    } = useSoftwarePartnerForm()

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData)

    const id = router.currentRoute.params.id

    if (!props.isFromRequestAccessForm) {
      if (id !== 'new' && !props.profile) {
        let path = `software-partners/${id}`
        if (
          store.state.auth.user.adminDocRef &&
          store.state.auth.user.adminDocRef.length > 0
        )
          path = store.state.auth.user.adminDocRef
        loadData(path)
      }

      if (props.profile) {
        spData.value = new SoftwarePartnerEntity(props.data)

        if (spData.value.logo) {
          logoUrl.value = createStorageUrl(spData.value.logo)
        }
      }
    }

    const loading = computed({
      get: () => store.state['software-partner'].loading,
    })

    /**
     * Submits the form data.
     *
     * @param {SoftwarePartnerEntity} data the data to be submitted.
     */
    const submit = (data) => {
      const ext = getExtension(logo.value)

      let logoPath = ''
      if (ext) {
        logoPath = `software-partners/${data.id}/logo.${ext}`
      } else if (logoUrl.value) {
        logoPath = data.logo
      }

      onSubmit(
        new SoftwarePartnerEntity({
          ...data,
          logo: logoPath,
        }),
        props.profile,
      )
    }

    return {
      spData,
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      submit,
      subscription,
      isTrialPlan,
      trialDays,
      uploadLogo,
      removeLogo,
      logo,
      logoUrl,
      refLogoInput,
      planType,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.vs__deselect svg {
  fill: #fff;
}

.dark-layout .vs__deselect svg {
  fill: #7367f0;
}

.b-avatar .b-avatar-img img {
  object-fit: contain;
}
</style>
