/**
 * Class responsible for keeping the subscription object properties.
 */
export default class SubscriptionProductEntity {
  constructor({
    id,
    name,
    active,
    description,
    defaultPriceData,
    price,
    metadata,
  } = {}) {
    /**
     * Property that defines id.
     *
     * @type {string}
     */
    this.id = id

    /**
     * Property that defines name.
     *
     * @type {string}
     */
    this.name = name

    /**
     * Property that defines the active.
     *
     * @type {boolean}
     */
    this.active = active

    /**
     * Property that defines the description.
     *
     * @type {string}
     */
    this.description = description

    /**
     * Property that defines the defaultPriceData.
     *
     * @type {DefaultPriceData}
     */
    this.defaultPriceData = defaultPriceData

    /**
     * Property that defines the price.
     *
     * @type {string}
     */
    this.price = price

    /**
     * Property that defines the metadata.
     *
     * @type {Metadata}
     */
    this.metadata = metadata
  }

  /**
   * Gets an object that represents the subscription object properties.
   */
  toStripe() {
    return {
      name: this.name,
      active: this.active,
      description: this.description,
      default_price_data: new DefaultPriceData(this.defaultPriceData).toMap(),
      metadata: new Metadata(this.metadata),
    }
  }

  /**
   * Converts the firestore data to the subscription object model.
   *
   * @param {SubscriptionProductEntity} data the firestore subscription object data.
   */
  static fromStripe(data) {
    /**
     * @type {SubscriptionProductEntity[]}
     */
    const list = []

    data.forEach((element) => {
      list.push(
        new SubscriptionProductEntity({
          ...element,
          defaultPriceData: element.default_price,
          price: DefaultPriceData.fromMap(element.price_data),
        }),
      )
    })

    return list
  }
}

/**
 * Class that keeps the price currency and amount.
 */
export class DefaultPriceData {
  constructor({ currency, unitAmount } = {}) {
    /**
     * Property that defines the price currency (eg. BRL).
     *
     * @type {string}
     */
    this.currency = currency

    /**
     * Property that defines the price amount.
     *
     * @type {number}
     */
    this.unitAmount = unitAmount
  }

  /**
   * Generates a new `DefaultPriceData` entity according to the given map.
   *
   * @param {DefaultPriceData} map an object containing the price data.
   * @returns a new `DefaultPriceData` entity.
   */
  static fromMap(map) {
    return new DefaultPriceData({
      unitAmount: map.unit_amount,
      currency: map.currency,
    })
  }

  /**
   * Generates a map object according to the current default price data.
   *
   * @returns a map that represents the default price data.
   */
  toMap() {
    return {
      unit_amount: this.unitAmount,
      currency: this.currency,
    }
  }
}

/**
 * Class that keeps some Firebase metadata.
 */
class Metadata {
  constructor({ firebaseRole } = {}) {
    /**
     * Property that defines the Firebase role.
     *
     * @type {string}
     */
    this.firebaseRole = firebaseRole
  }

  /**
   * Generates a map object according to the current entity.
   *
   * @returns an object containing the metadata information.
   */
  toMap() {
    return {
      metadata: {
        firebaseRole: this.firebaseRole,
      },
    }
  }

  /**
   * Generates a `Metadata` entity according to the given map.
   *
   * @param {Metadata} map an object containing the metadata info.
   * @returns a new `Metadata` entity.
   */
  static fromMap(map) {
    return new Metadata({
      firebaseRole: map.firebaseRole,
    })
  }
}
