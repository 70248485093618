<template>
  <div>
    <b-table
      ref="refdataListTable"
      :items="data"
      :fields="tableColumns"
      responsive
      show-empty
      primary-key="id"
      empty-text="No entries"
      :busy="$store.state.laboratory.loadingdata"
    >
      <!-- Table Loading -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" small />

          <strong class="ml-1">Loading...</strong>
        </div>
      </template>

      <template #cell(updatedAt)="data">
        <span>{{ parseDateToString(data.item.updatedAt, 'mm dd yyyy') }}</span>
      </template>

      <template #cell(createdAt)="data">
        <span>{{ parseDateToString(data.item.createdAt, 'mm dd yyyy') }}</span>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"> Total of {{ data.length }} entries </span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            :per-page="8"
            :total-rows="data ? data.length : 0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BTable, BPagination, BRow, BCol } from 'bootstrap-vue'
import { parseDateToString } from '@/utils/dates'
import i18n from '@/libs/i18n'

export default {
  components: {
    BTable,
    BPagination,
    BRow,
    BCol,
  },
  props: {
    data: {
      type: Array,
    },
  },
  data() {
    return {
      parseDateToString,
      currentPage: 1,
      tableColumns: [
        {
          key: 'nameFantasy',
          label: i18n.t('admin.labs.listLabs.labsHeaders.name'),
          sortable: true,
        },
        {
          key: 'nameRegistered',
          label: i18n.t('admin.labs.listLabs.labsHeaders.registeredName'),
          sortable: true,
        },
        {
          key: 'address.line1',
          label: i18n.t('admin.labs.listLabs.labsHeaders.addressLine1'),
          sortable: true,
        },
        {
          key: 'address.line2',
          label: i18n.t('admin.labs.listLabs.labsHeaders.addressLine2'),
          sortable: true,
        },
        {
          key: 'address.country',
          label: i18n.t('admin.labs.listLabs.labsHeaders.country'),
          sortable: true,
        },
        {
          key: 'address.zipCode',
          label: i18n.t('admin.labs.listLabs.labsHeaders.zip'),
          sortable: true,
        },
        {
          key: 'address.number',
          label: i18n.t('admin.labs.listLabs.labsHeaders.number'),
          sortable: true,
        },
        {
          key: 'updatedAt',
          label: i18n.t('admin.labs.listLabs.labsHeaders.updatedAt'),
          sortable: true,
        },
        {
          key: 'createdAt',
          label: i18n.t('admin.labs.listLabs.labsHeaders.createdAt'),
          sortable: true,
        },
        { key: 'actions', label: '' },
      ],
    }
  },
}
</script>