import Entity from './base.entity'

/**
 * Class responsible for keeping the subscriptions properties.
 */
export default class SubscriptionsEntity extends Entity {
  constructor({
    id,
    cancelAtPeriodEnd,
    currentPeriodEnd,
    currentPeriodStart,
    customer,
    defaultPaymentMethod,
    description,
    items,
    latestInvoice,
    status,
    startDate,
    endedAt,
  } = {}) {
    super({ id, createdAt, updatedAt })

    /**
     * Property that defines cancellation end date.
     *
     * @type {boolean}
     */
    this.cancelAtPeriodEnd = cancelAtPeriodEnd

    /**
     * Property that defines the current period end.
     *
     * @type {number}
     */
    this.currentPeriodEnd = currentPeriodEnd

    /**
     * Property that defines the current period start.
     *
     * @type {number}
     */
    this.currentPeriodStart = currentPeriodStart

    /**
     * Property that defines the customer.
     *
     * @type {string}
     */
    this.customer = customer

    /**
     * Property that defines the default payment method.
     *
     * @type {string}
     */
    this.defaultPaymentMethod = defaultPaymentMethod

    /**
     * Property that defines the description.
     *
     * @type {string}
     */
    this.description = description

    /**
     * Property that defines the items.
     *
     * @type {array}
     */
    this.items = items

    /**
     * Property that defines the latest invoice.
     *
     * @type {string}
     */
    this.latestInvoice = latestInvoice

    /**
     * Property that defines the status.
     *
     * @type {string}
     */
    this.status = status || 'pending'

    /**
     * Property that defines the start date.
     *
     * @type {number}
     */
    this.startDate = startDate

    /**
     * Property that defines the end date.
     *
     * @type {number}
     */
    this.endedAt = endedAt
  }

  /**
   * Gets an object that represents the subscription properties.
   */
  toStripe() {
    return {
      cancel_at_period_end: this.cancelAtPeriodEnd,
      current_period_end: this.currentPeriodEnd,
      current_period_start: this.currentPeriodStart,
      customer: this.customer,
      default_payment_method: this.defaultPaymentMethod,
      description: this.description,
      items: this.items,
      latest_invoce: this.latestInvoice,
      status: this.status,
      start_date: this.startDate,
      end_date: this.endedAt,
    }
  }

  /**
   * Converts the firestore data to the subscriptions model.
   *
   * @param {SubscriptionsEntity} data the firestore subscriptions data.
   */
  static fromStripe(data) {
    return new SubscriptionsEntity(data)
  }
}
