import Stripe from 'stripe'

import { cF } from '@/utils/cloud-functions'

import CustomerEntity from '../entities/customer.entity'
import SubscriptionProductEntity from '../entities/subscription-product.entity'
import SubscriptionsEntity from '../entities/subscriptions.entity'

import { callHttpsCallableFunction } from './firebase'

/**
 * Class that keeps the stripe api calling methods.
 */
export default class StripeApi {
  /**
   * Creates a stripe product according to the given data.
   *
   * @param {SubscriptionProductEntity} product the product to be created.
   * @returns {Promise<import('firebase/functions').HttpsCallableResult<{ msg: string, data: Stripe.Product }>>} an object that contains the message and data.
   */
  async createProduct(product) {
    return callHttpsCallableFunction(cF.createSubscriptionProduct, product)
  }

  /**
   * Creates a checkout subscription according to the given data.
   *
   * @param {CheckoutSubscriptionSession} data the subscription data.
   * @returns {Promise<import('firebase/functions').HttpsCallableResult<{ msg: string, data: Stripe.Checkout.Session }>>} an object that contains the message and data.
   */
  async subscribeToProduct(data) {
    return callHttpsCallableFunction(cF.subscribeToProduct, data)
  }

  /**
   * Gets all products from stripe.
   *
   * @returns {Promise<import('firebase/functions').HttpsCallableResult<Stripe.Product[]>>} a list with all stripe products.
   */
  async getProducts() {
    return callHttpsCallableFunction(cF.getAllSubscriptionProducts)
  }

  /**
   * Updates a subscription into the customer.
   *
   * @param {{ subscription: SubscriptionsEntity, price: string }} data
   * @returns {Promise<import('firebase/functions').HttpsCallableResult<{ msg: string }>>} an object with a message that represents the update success.
   */
  async updateSubscription(data) {
    return callHttpsCallableFunction(cF.updateSubscription, data)
  }

  /**
   * Cancels some customer's subscription.
   *
   * @param {{id: string, user: string}} data an object containing the subscription and user ids.
   */
  async cancelSubscription(data) {
    return callHttpsCallableFunction(cF.cancelSubscription, data)
  }

  /**
   * Creates a customer according to the given data.
   *
   * @param {CustomerEntity} customer the customer data.
   * @returns {Promise<import('firebase/functions').HttpsCallableResult<{msg: string, data: Stripe.Customer}>>} an object containing the message and the customer data.
   */
  createCustomer(customer) {
    return callHttpsCallableFunction(cF.createCustomer, customer)
  }

  /**
   * Gets all customers from the stripe.
   *
   * @returns {Promise<import('firebase/functions').HttpsCallableResult<Stripe.Customer[]>>} a list of customers.
   */
  getAllCustomers() {
    return callHttpsCallableFunction(cF.getAllCustomers)
  }

  /**
   * Gets a customer according to its id.
   *
   * @param {string} customerId the customer id.
   * @returns {Promise<import('firebase/functions').HttpsCallableResult<Stripe.Customer>>} the found customer.
   */
  async getCustomer(customerId) {
    return callHttpsCallableFunction(cF.getCustomer, customerId)
  }
}
