<template>
  <div v-if="address">
    <div class="d-flex mt-2">
      <feather-icon icon="MapPinIcon" size="19" />
      <h4 class="mb-0 ml-50">{{ $t('admin.labs.form.address') }}</h4>
    </div>

    <b-row class="mt-1">
      <b-col cols="12" md="6" lg="4">
        <validation-provider
          #default="validationContext"
          :name="$t('admin.labs.form.addressLine1')"
          rules="required"
        >
          <b-form-group
            :label="$t('admin.labs.form.addressLine1')"
            label-for="Address Line 1"
          >
            <b-form-input
              id="addressline1"
              v-model="address.line1"
              :placeholder="$t('admin.labs.form.addressLine1')"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col cols="12" md="6" lg="4">
        <validation-provider
          #default="validationContext"
          :name="$t('admin.labs.form.number')"
          rules="required|integer|positive"
        >
          <b-form-group
            :label="$t('admin.labs.form.number')"
            label-for="number"
          >
            <b-form-input
              id="number"
              v-model="address.number"
              :placeholder="$t('admin.labs.form.number')"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col cols="12" md="6" lg="4">
        <validation-provider
          #default="validationContext"
          :name="$t('admin.labs.form.addressLine2')"
        >
          <b-form-group
            :label="$t('admin.labs.form.addressLine2')"
            label-for="Address Line 2"
          >
            <b-form-input
              id="addressline2"
              v-model="address.line2"
              :placeholder="$t('admin.labs.form.addressLine2')"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col cols="12" md="6" lg="4">
        <validation-provider
          #default="validationContext"
          :name="$t('admin.labs.form.zipCode')"
          rules="required"
        >
          <b-form-group
            :label="$t('admin.labs.form.zipCode')"
            label-for="zipCode"
          >
            <b-form-input
              id="zipcode"
              v-model="address.zipCode"
              :placeholder="$t('admin.labs.form.zipCode')"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col cols="12" md="6" lg="4">
        <validation-provider
          #default="validationContext"
          :name="$t('admin.labs.form.country')"
          rules="required"
        >
          <b-form-group
            :label="$t('admin.labs.form.country')"
            label-for="country"
          >
            <country-select
              class="country-form"
              v-model="address.country"
              topCountry="BR"
              :country="address.country"
              :usei18n="false"
              :placeholder="$t('profile.editUserForm.country')"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, integer, positive } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

import vueCountryRegionSelect from 'vue-country-region-select'

export default {
  props: {
    address: {
      type: Object,
    },
  },

  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      required,
      integer,
      positive,
    }
  },

  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      {},
    )

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style>
.country-form {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;

  display: block;
  width: 100%;
  height: 2.714rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  transition: border-color 0.15s;
}
</style>