import { DefaultPriceData } from './subscription-product.entity'

/**
 * Class responsible for keeping the checkout session subscription object properties.
 */
export default class CheckoutSubscriptionSession {
  constructor({
    customer,
    lineItems,
    successUrl,
    cancelUrl,
    subscriptionData,
  } = {}) {
    /**
     * Property that defines customer id.
     *
     * @type {string}
     */
    this.customer = customer
    /**
     * Property that defines the items that the customer is buying.
     *
     * @type {List<DefaultPriceData>}
     */
    this.lineItems = lineItems

    /**
     * Property that defines the success URL to be redirected.
     *
     * @type {string}
     */
    this.successUrl = successUrl

    /**
     * Property that defines the cancel URL to be redirected.
     *
     * @type {string}
     */
    this.cancelUrl = cancelUrl

    /**
     * Property that defines extra content related to subscription
     *
     * @type {string}
     */
    this.subscriptionData = subscriptionData
  }

  /**
   * Gets an object that represents the subscription object properties.
   */
  toStripe() {
    return {
      customer: this.customer,
      lineItems: this.lineItems,
      successUrl: this.successUrl,
      cancelUrl: this.cancelUrl,
      subscriptionData: this.subscriptionData,
    }
  }

  /**
   * Converts the firestore data to the subscription object model.
   *
   * @param {CheckoutSubscriptionSession} data the firestore subscription object data.
   * @returns {CheckoutSubscriptionSession}
   */
  static fromStripe(data) {
    data.forEach((element) => {
      list.push(
        new CheckoutSubscriptionSession({
          ...element,
        }),
      )
    })

    return list
  }
}
