import StripeApi from '@/api/stripe'

import SubscriptionProductEntity from '@/entities/subscription-product.entity'
import CheckoutSubscriptionSession from '@/entities/checkout-session-subscription.entity'

import Controller from './base.controller'

const plansCollection = 'plans'

/**
 * Class responsible for keeping all the Subscription logic.
 */
export default class SubscriptionController extends Controller {
  constructor() {
    super()

    /**
     * Property that defines the stripe API.
     */
    this.stripeAPI = new StripeApi()
  }

  /**
   * Gets all database plans according to the given constraints.
   *
   * @param {QueryConstraint[]} constraints the query filters.
   * @returns an array of plans.
   * @throws a Firebase exception.
   */
  async getAll(constraints) {
    const snapshot = await super.getAll([plansCollection], constraints)

    const plans = snapshot.docs.map((p) => ({ ...p.data(), id: p.id }))

    const promises = plans.map(async (plan) => {
      const prices = await super.getAll([plansCollection, plan.id, 'prices'])

      plan['prices'] = prices.docs.map((d) => ({ ...d.data(), id: d.id }))
    })

    await Promise.all(promises)
    return plans
  }

  /**
   * Gets a plan from the database according to the given id.
   *
   * @param {string} id the plan id.
   * @returns a plan object.
   * @throws a Firebase exception.
   */
  async getOne(id) {
    const document = await super.getById([plansCollection, id])

    if (!document.exists()) {
      return null
    }

    const plan = { ...document.data(), id: document.id }

    const prices = await super.getAll([plansCollection, plan.id, 'prices'])

    plan['prices'] = prices.docs.map((d) => ({ ...d.data(), id: d.id }))

    return plan
  }

  /**
   * Creates a stripe product according to the given data.
   *
   * @param {SubscriptionProductEntity} data the product to be created.
   * @returns an object that contains the message and data.
   */
  async createProduct(data) {
    return this.stripeAPI.createProduct(data.toStripe())
  }

  /**
   * Creates a checkout subscription according to the given data.
   *
   * @param {CheckoutSubscriptionSession} data the subscription data.
   * @returns an object that contains the message and data.
   */
  async subscribeToProduct(data) {
    return this.stripeAPI.subscribeToProduct(data.toStripe())
  }

  /**
   * Gets all products from stripe.
   *
   * @returns a list with all stripe products.
   */
  async getProducts() {
    return SubscriptionProductEntity.fromStripe(
      (await this.stripeAPI.getProducts()).data.data,
    )
  }

  /**
   * Cancels some customer's subscription.
   *
   * @param {{id: string, user: string}} data an object containing the subscription and user ids.
   */
  async cancelSubscription(data) {
    return this.stripeAPI.cancelSubscription(data)
  }

  /**
   * Creates a customer according to the given data.
   *
   * @param {CustomerEntity} data the customer data.
   * @returns an object containing the message and the customer data.
   */
  async createCustomer(data) {
    return this.stripeAPI.createCustomer(data)
  }

  /**
   * Gets all customers from the stripe.
   *
   * @returns a list of customers.
   */
  async getAllCustomers() {
    return this.stripeAPI.getAllCustomers()
  }

  /**
   * Gets a customer according to its id.
   *
   * @param {string} id the customer id.
   * @returns the found customer.
   */
  async getCustomer(id) {
    return this.stripeAPI.getCustomer(id)
  }
}
